.ant-layout-header .ant-menu {
    /* float: right; */
}

.header {
    padding: 0 200px;
    border-bottom: 1px solid #eff2f5;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.header-left {
    padding: 8px !important;
}

.header-icon {
    float: left;
    margin-right: 8px;
}

.header-title {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    line-height: 2em;
    font-family: 'Work Sans';
    font-weight: 500;
}

.header-desc {
    font-size: 10px;
    font-weight: 500;
    color: #a1a1a1;
    line-height: 1em;
}

@media only screen and (max-width: 869px) {
    .header {
        padding: 0 16px;
    }
}

@media only screen and (max-width: 768px) {
    .header {
        padding: 0 16px;
    }

}

@media only screen and (max-width: 425px) {}