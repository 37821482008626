.form-alumni {
    padding: 0;
    /* margin-top: 64px; */
}

.image-profile .ant-upload {
    width: 100%;
    min-height: 150px;
}

.image-profile .ant-upload-list-picture-card-container {
    width: 100%;
    min-height: 150px;
}

.image-profile .ant-upload-list-item {
    padding: 0;
    border: 0;
}

.image-profile img {
    border-radius: 8px !important;
}

.content-page-img {
    height: 400px;
    width: 100%;
    overflow: hidden;
    /* margin: 0 -24px; */
}

.content-page-img .ant-image {
    width: 100%;
    height: 400px;
}

.modal-content-page .ant-modal-body{
    padding: 0px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before{
    width: 0 !important;
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 869px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 425px) {
    .content-page-img {
        height: 200px;
    }
    .content-page-img .ant-image {
        height: 200px;
    }
}

@media only screen and (max-width: 375px) {}

@media only screen and (max-width: 320px) {}


.full-height {
    height: 100%;
    position: fixed;
    top: 0;
    background: #0000005c;
    width: 100%;
    left: 0;
    z-index: +999;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video{
    width: 100% !important;
}


.bg-camera {
    height: 100%;
    position: fixed;
    top: 0;
    background: #0000005c;
    width: 100%;
    left: 0;
    z-index: +999;
    text-align: right;
}

.content-camera {
    margin: auto;
    margin-top: 60px;
    width: 60%;
    /* min-height: 40%; */
    border: 8px solid #00000031;
    position: relative;
}

.icon-change-camera {
    z-index: +9999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 24px;
    right: 40px;
    transform: translate(-50%, -50%);
}

.icon-close-camera {
    z-index: +9999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 24px;
    right: 8px;
    transform: translate(-50%, -50%);
}

.react-html5-camera-photo {
    margin-bottom: -5px !important;
}