html {
  scroll-behavior: smooth;
}

.layout {
  /* padding: 0 100px; */
  background-color: #fff !important;
}

.content {
  padding: 0 200px;
  margin-top: 64px;
}

.ant-layout-header {
  background: #fff !important;
}

.ant-btn-primary {
  border-color: #0ba475 !important;
  background: #10B582 !important;
}

.site-page-header-ghost-wrapper {
  padding: 24px 164px;
  /* background-color: #f5f5f5; */
  /* background-color: #f0ebf8; */
  min-height: 800px;
}

.ant-layout-footer {
  position: relative !important;
}

.ant-card-hoverable:hover {
  border-radius: 8px;
  box-shadow: 0 2px 8px #f0f1f2 !important;
}

.ant-card-bordered {
  border: 1px solid #f8f8f8 !important;
}

.ant-layout-content {
  min-height: 900px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  /* background-color: DodgerBlue; */
}

.flex-container > div {
  /* background-color: #f1f1f1; */
  width: 25%;
  margin: 16px 8px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.ant-form-item{
  margin: 0 0 8px !important;
}

.ant-modal-content{
  border-radius: 8px !important;
}

.ant-modal-body{
  max-height: 720px;
  overflow-y: auto;
}

.ant-modal-header {
  /* padding: 10px !important; */
  border-bottom: 0 !important;
}

.ant-modal-footer{
  border-top: 0 !important;
}

.ant-card-meta-title{
  padding: 24px 0 8px 0;
}
@media only screen and (max-width: 869px) {
  .content {
    padding: 16px;
  }

  .content-first {
    padding: 64px 16px 0 16px;
  }

  .site-page-header-ghost-wrapper {
    padding: 74px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding: 16px;
  }

  .content-first {
    padding: 64px 16px 0 16px;
  }
}

@media only screen and (max-width: 425px) {}