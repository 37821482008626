.content-home {
    background-image: url(`../../../../../assets/images/img-jumbotron-man.jpg);
    /* min-height: 700px !important; */
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: initial;
    padding: 120px 0;
}

.text-lebih-canggih {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.text-berbasis-teknologi {
    margin-top: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    /* max-width: 570px; */
}

.text-man-digital {
    margin-top: 16px;
    /* max-width: 570px; */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background: #fff;
    border-radius: 8px;
    padding: 16px 16px 16px 0;
}

.box-pengumuman {
    padding: 16px 16px 24px 16px;
    /* max-width: 550px; */
    /* height: 80px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), inset 3px 0px 0px #FF4D4F;
    border-radius: 5px;
}

.label-bell {
    float: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 11.4px;
    gap: 14.25px;
    width: 57px;
    height: 57px;
    background: #FFF1F0;
    border-radius: 141.075px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 8px;
}

.text-pengumuman {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FF4D4F;
    width: 100%;
}

.text-pengumuman-desc {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    width: 100%;
}

.aplikasi-pendukung {
    /* position: absolute; */
    width: 100%;
    min-height: 489px;
    background-image: url(`../../../../../assets/images/bg-app-pendukung.jpg);
    padding-top: 16px;
}

.text-aplikasi-pendukung {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    margin: 0 auto;
    text-align: center;
}

.box-aplikasi-pendukung-div {
    margin: 24px 0px;
}

.box-aplikasi-pendukung-div .title {
    font-size: 12px;
    text-align: center;
    min-height: 40px;
}

.box-aplikasi-pendukung {
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-size: 10px;
    line-height: 2em;
}

.box-aplikasi-pendukung img {
    width: 30%;
}

.text-artikel {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #262626;
    margin: 0 auto 24px auto;
    text-align: center;
}

.text-prestasi-diraih {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #262626;
}

.artikel {
    width: 100%;
    min-height: 489px;
    background-image: url(`../../../../../assets/images/bg-article.jpg);
    padding-top: 16px;
}

.gerbang-utama {
    margin: 120px 0;
    padding: 24px;
    height: 244px;
    background: linear-gradient(180deg, rgba(16, 181, 130, 0.99) 50%, rgba(16, 181, 130, 0) 137.91%);
    border-radius: 8px;
    background-image: url(`../../../../../assets/images/img-cta.png), url(`../../../../../assets/images/bg-cta.jpg);
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
}

.text-gerbang-utama {
    color: #FFFFFF;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    max-width: 530px;
}

.footer-man1gresik-logo {
    background-image: url(`../../../../../assets/images/logo-man.png);
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 72px;
    height: 71.28px;
}

.footer-man1gresik-title {
    margin: 0 auto;
    width: 157px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #262626;
}

.footer-man1gresik-desc {
    margin: 0 auto;
    width: 323px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8C8C8C;
}

.footer-man1gresik-menu {
    margin: 24px auto;
    width: 370px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.link-menu {
    color: #000;
}

.ant-card-cover {
    overflow: hidden;
    /* height: 210px; */
}

.ant-card-body {
    min-height: 155px !important;
    max-height: 100px !important;
    overflow: hidden;
    padding: 0 24px;
}

.spin-loading-content {
    margin: 8% 0;
    padding: 30px 50px;
    text-align: center;
    background: #ffffff;
    border-radius: 4px;
}

.content-home-div {
    max-width: 570px;
}

.code-box-shape {
    width: 23.7%;
    height: 100%;
    float: left;
    margin: 8px;
}

.zoom {
    transition: transform .2s;
    /* Animation */
}

.zoom:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.btn-fullscreen {
    cursor: pointer;
    padding: 4px;
    border-radius: 2px;
    color: #aaa;
}

.btn-fullscreen:hover {
    background: #f0f0f0;
}

@media only screen and (max-width: 1440px) {
    .code-box-shape {
        width: 23.4%;
    }

    .box-aplikasi-pendukung img {
        width: 50%;
    }
}

@media only screen and (max-width: 1366px) {
    .code-box-shape {
        width: 23.3%;
    }
}

@media only screen and (max-width: 1024px) {
    .code-box-shape {
        width: 22.4%;
    }

    .header{
        padding: 0 24px;
    }
    .content {
        padding: 0 24px;
    }

    .content-home {
        background-size: 500px;
        padding: 120px 0;
    }
}

@media only screen and (max-width: 869px) {
    .content-home {
        background-size: 100%;
        padding: 0;
        padding-top: 24px;
        margin-bottom: 0;
    }

    .content-home-div {
        background-color: #ffffffd6;
        padding-bottom: 0px;
        max-width: 100%;
    }

    .text-lebih-canggih {
        text-align: center;
    }

    .text-berbasis-teknologi {
        text-align: center;
        font-size: 64px;
        max-width: 100%;
    }

    .text-man-digital {
        text-align: center;
        margin: 24px auto;
    }

    .text-pengumuman {
        font-size: 12px;
        line-height: 24px;
    }

    .text-pengumuman-desc {
        font-size: 12px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .content-home {
        background-size: 100%;
        padding: 24px 0 0 0;
    }

    .content-home-div {
        background-color: #ffffffd6;
        padding-bottom: 98px;
        max-width: 100%;
    }

    .text-lebih-canggih {
        text-align: center;
    }

    .text-berbasis-teknologi {
        text-align: center;
        font-size: 64px;
        max-width: 100%;
    }

    .text-man-digital {
        text-align: center;
        margin: 24px auto;
    }

    .text-pengumuman {
        font-size: 12px;
        line-height: 24px;
    }

    .text-pengumuman-desc {
        font-size: 12px;
        line-height: 24px;
    }

    .code-box-shape {
        width: 22.4%;
    }

    .box-aplikasi-pendukung img {
        width: 70%;
    }

}

@media only screen and (max-width: 425px) {

    .content-home-div {
        min-height: 500px;
    }

    .text-lebih-canggih {
        text-align: center;
    }

    .text-berbasis-teknologi {
        text-align: center;
        font-size: 30px;
        line-height: 36px;
    }

    .text-man-digital {
        font-size: 10px;
        line-height: 20px;
    }

    .text-pengumuman {
        font-size: 12px;
        line-height: 24px;
    }

    .text-pengumuman-desc {
        font-size: 12px;
        line-height: 24px;
    }

    .text-prestasi-diraih {
        font-size: 12px;
    }

    .footer-man1gresik-menu {
        width: 324px;
        font-size: 12px;
    }

    .gerbang-utama-div {
        background: #1c775b52;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        text-align: center;
        padding: 16px;
    }

    .code-box-shape {
        width: 45.9%;
    }

    .content {
        margin-top: 24px;
    }

    .content-home {
        padding: 8px 0;
    }

    .content-home-div {
        padding-bottom: 16px;
        min-height: 400px;
    }
}

@media only screen and (max-width: 375px) {
    .code-box-shape {
        width: 45%;
    }

    .content {
        margin-top: 24px;
    }

    .content-home {
        padding: 8px 0;
    }

    .content-home-div {
        padding-bottom: 16px;
        min-height: 400px;
    }
}

@media only screen and (max-width: 320px) {
    .code-box-shape {
        width: 44%;
    }

    .content {
        margin-top: 24px;
    }

    .content-first {
        padding: 64px 16px 0 16px;
    }

    .content-home {
        padding: 8px 0;
    }

    .content-home-div {
        padding-bottom: 16px;
        min-height: 400px;
    }

    .aplikasi-pendukung {
        padding-top: 24px;
    }
}


.ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=ant-tabs-nav-wrap-ping]),
.ant-tabs-centered>div>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*=ant-tabs-nav-wrap-ping]) {
    justify-content: end;
}